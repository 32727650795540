<h1 mat-dialog-title><span>Filter tags</span></h1>
<mat-dialog-content>
    <mat-radio-group fxLayout="column" (change)="changeFilter
    ($event)" [(ngModel)]="filterValueId">
        <mat-radio-button *ngFor="let filter of filters" [value]="filter.id">
            <span>{{ filter.name }}</span>
        </mat-radio-button>
    </mat-radio-group>


</mat-dialog-content>
<mat-dialog-actions>

</mat-dialog-actions>
