<tickist-nav></tickist-nav>

<mat-sidenav-container *ngIf="leftSidenavVisibility" style="flex: 1">
    <mat-sidenav [mode]="leftSidenavVisibility.mode"
                 [opened]="leftSidenavVisibility.open" (close)="closeLeftSidenavVisibility()"
                 [fixedInViewport]="'fixed'" [fixedTopGap]="79">
        <router-outlet name="left"></router-outlet>

    </mat-sidenav>

    <mat-sidenav-content>

        <router-outlet #contentOutlet name="content"></router-outlet>
    </mat-sidenav-content>


</mat-sidenav-container>
<tickist-add-task-footer-button ></tickist-add-task-footer-button>




