import { Component } from "@angular/core";

@Component({
    selector: "tickist-dialog-layout",
    templateUrl: "./auth-layout.component.html",
    styleUrls: ["./auth-layout.component.scss"],
})
export class AuthLayoutComponent {
    constructor() {}
}
