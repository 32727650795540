import { Component } from "@angular/core";

@Component({
    selector: "tickist-nav-bar-landing-page",
    templateUrl: "./nav-bar-auth-page.component.html",
    styleUrls: ["./nav-bar-auth-page.component.scss"],
})
export class NavBarAuthPageComponent {
    constructor() {}
}
