<mat-toolbar>
    <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-around center">
        <a class="logo" href="https://tickist.com">
            <div class="docs-logotype"><img src="/assets/images/logo_32.png" alt="Tickist logo"></div>
        </a>
        <span fxFlex></span>
        <button mat-button [routerLink]="['/signup']"><fa-icon [icon]="'user-plus'"></fa-icon><span>Sign up</span> </button>
        <button mat-button [routerLink]="['/login']"><fa-icon [icon]="'sign-in-alt'"></fa-icon> <span>Log in</span> </button>

        </div>
</mat-toolbar>
