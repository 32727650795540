<div id="project" fxLayout="row" fxLayoutAlign="center center"
     [ngClass]="{'isActive': isSelected}" (click)="navigateTo('/home/projects', project.id, $event)">
    <div class="content" fxFlex fxLayout="row" fxLayoutAlign="center center" *ngIf="project">
        <span *ngIf='project.level===1' fxFlex="15px"></span>
        <span *ngIf='project.level===2' fxFlex="30px"></span>
        <fa-icon *ngIf='!activeCheckboxMode' [icon]="project.icon" [ngStyle]="{'color': project.color}"></fa-icon>
        <mat-checkbox [(ngModel)]="isActive" *ngIf="isSelected && activeCheckboxMode"
                      (change)="changeId()">
        </mat-checkbox>
        <span #projectNameDiv class="project-name" *ngIf="!tooltip" (resized)="onResized()"> {{ project.name }} </span>
        <span #projectNameDiv class="project-name" *ngIf="tooltip" (resized)="onResized()" [matTooltip]="project.name"> {{ project.name }} </span>
        <span fxFlex></span>
        <span *ngIf="!isMenuVisible" class="tasks-counter"> {{ project.tasksCounter }} </span>
        <tickist-menu-button *ngIf="isMenuVisible" [icon]="'ellipsis-v'"
                     [matMenuTriggerFor]="menu"
                     (onMenuClose)="changeFastMenuVisible(false)"
                     (onMenuOpen)="changeFastMenuVisible(true)"
                     data-cy="project-fast-menu">
        </tickist-menu-button>


    </div>


</div>

<mat-menu #menu="matMenu" >
    <button mat-menu-item (click)="navigateToEditProjectView(project.id)">Edit</button>
    <button mat-menu-item *ngFor="let projectType of anotherProjectTypes" (click)="convertTo(projectType)">
        Convert to {{ projectType }}</button>
    <button mat-menu-item *ngIf="canHaveChildProjects" [routerLink]="[homeRoutesName, editProjectSettingsRoutesName,'createWithAncestor', project.id ]">Create child project</button>
    <button mat-menu-item (click)="deleteOrLeaveProject()">{{ deleteOrLeave }}</button>


</mat-menu>
