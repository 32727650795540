<div *ngIf="{
        allNotificationCounter: allNotificationCounter$ |async,
        allUnreadNotificationCounter: allUnreadNotificationCounter$ | async
    } as data" [matMenuTriggerFor]="appMenu" data-cy='notification-icon'>

    <tickist-menu-button matBadge="{{ data.allUnreadNotificationCounter }}" matBadgeOverlap="true"
                         matBadgePosition="above after" [icon]="'bell'"
                         [iconPrefix]="'far'" [color]="'white'" [fontSize]="'28'" >
    </tickist-menu-button>

    <mat-menu #appMenu="matMenu" (click)="$event.stopPropagation()">
        <tickist-notification *ngFor="let notification of (allNotifications$ | async)"
                              [notification]="notification"></tickist-notification>
        <div fxLayout="row" fxLayoutAlign="center center" >
            <button *ngIf="data.allUnreadNotificationCounter > 0" type="button" data-cy="markAllAs" mat-button
                    class="button"
                    (click)="markAllAsRead($event)">Mark all as read
            </button>
            <div class="zero-notifications" *ngIf="data.allNotificationCounter === 0">
                <span>You have 0 notifications to read.</span>
            </div>
        </div>

    </mat-menu>
</div>
