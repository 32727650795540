<div style="min-height: 100%; height: 100%;">
    <mat-card>
        <mat-accordion displayMode="flat">
            <mat-expansion-panel [routerLink]="[home,dashboard]" hideToggle="true" [expanded]="'false'"
                                 class="always-collapsed">
                <mat-expansion-panel-header expandedHeight="48px">
                    <mat-panel-title>
                        <fa-icon [icon]="'laptop-house'"></fa-icon>
                        Dashboard
                    </mat-panel-title>
                </mat-expansion-panel-header>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <fa-icon [icon]="'calendar-day'"></fa-icon>
                        Weekdays
                    </mat-panel-title>

                </mat-expansion-panel-header>
                <tickist-weekdays-list></tickist-weekdays-list>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <fa-icon [icon]="'calendar'"></fa-icon>
                        Future
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <tickist-future-list></tickist-future-list>
            </mat-expansion-panel>

            <mat-expansion-panel (click)="navigateToInbox()" hideToggle="true" [expanded]="'false'"
                                 class="always-collapsed">
                <mat-expansion-panel-header expandedHeight="48px">
                    <mat-panel-title>
                        <fa-icon [icon]="'inbox'"></fa-icon>
                        Inbox ({{ inboxTasksCounter$ | async}})
                    </mat-panel-title>
                </mat-expansion-panel-header>

            </mat-expansion-panel>


            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <fa-icon [icon]="'folder'"></fa-icon>
                        Active projects ({{ activeProjectCounter$ | async}})
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <tickist-projects-list [projectType]="projectsTypes.active"></tickist-projects-list>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <fa-icon [icon]="['far', 'lightbulb']"></fa-icon>
                        Someday/maybe projects ({{ maybeProjectCounter$ | async}})
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <tickist-projects-list [projectType]="projectsTypes.maybe"></tickist-projects-list>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <fa-icon [icon]="'business-time'"></fa-icon>
                        Routine reminder ({{ remiderProjectCounter$ | async}})
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <tickist-projects-list [projectType]="projectsTypes.routineReminder"></tickist-projects-list>
            </mat-expansion-panel>

            <mat-expansion-panel (click)="navigateToTasksTreeView()" hideToggle="true" [expanded]="'false'"
                                 class="always-collapsed">
                <mat-expansion-panel-header expandedHeight="48px">
                    <mat-panel-title>
                        <fa-icon [icon]="'sitemap'"></fa-icon>
                        Tasks tree view
                    </mat-panel-title>
                </mat-expansion-panel-header>

            </mat-expansion-panel>

            <mat-expansion-panel (afterExpand)="navigateToTags()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <fa-icon [icon]="'tags'"></fa-icon>
                        Tags
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <tickist-tags-list></tickist-tags-list>
            </mat-expansion-panel>
            <mat-expansion-panel (click)="navigateToStatisticsView()" hideToggle="true" [expanded]="'false'"
                                 class="always-collapsed" *featureFlag="'statistics'">
                <mat-expansion-panel-header expandedHeight="48px">
                    <mat-panel-title>
                        <fa-icon [icon]="'chart-line'"></fa-icon>
                        Statistics
                    </mat-panel-title>
                </mat-expansion-panel-header>

            </mat-expansion-panel>
        </mat-accordion>

    </mat-card>


</div>
