<div fxLayout="column">
    <div class="tag-row" fxLayout="row" fxLayoutAlign="space-around center" [ngClass]="{'is-active': isActive}">
        <div class="tag-name" fxFlex  fxLayout="row" fxLayoutAlign="start center" [matTooltip]="label">
            <mat-checkbox *ngIf="isCheckboxModeEnabled" class="checkbox" (change)="selectTags($event)" [checked]="isChecked"></mat-checkbox>
            <fa-icon [ngClass]="'tag-icon'" *ngIf="!isCheckboxModeEnabled" [icon]="'tag'"></fa-icon>
            <span (click)="selectTag()">{{ label }}</span>
        </div>
        <div (click)="selectTag()" fxFlex></div>
        <span (click)="selectTag()" class="task-counter">{{ tasksCounter }}</span>
        <fa-icon *ngIf="tag" [icon]="'edit'" [ngClass]="'tag-edit-icon'" data-cy="editTag" (click)="toggleEditMode()"></fa-icon>
    </div>


    <form [formGroup]="editTagForm" novalidate (ngSubmit)="editTag(editTagForm.value)" *ngIf="tag && editMode" >
        <mat-form-field [ngStyle]="{'margin-left':'20px', 'margin-top': '5px'}">
            <input matInput name="edit-tag-name" formControlName="name" placeholder="Tag name">
            <mat-error *ngIf="editTagForm.controls['name'].hasError('required')">Name is required</mat-error>
        </mat-form-field>
        <button mat-button type="submit" data-cy="changeTagName">Change name</button>
        <button mat-button type="button" (click)="deleteTag()" data-cy="deleteTag">Delete</button>
    </form>
</div>
