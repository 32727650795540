<div fxLayout="row">
    <div fxFlex=""></div>
    <tickist-menu-button fxFlex="23px" [icon]="'filter'" (click)="openFilterDialog()">
    </tickist-menu-button>
</div>
<div fxLayout="column">
    <tickist-tag fxFlex label="All tasks" id="allTasks"></tickist-tag>
    <tickist-tag fxFlex label="All tags" id="allTags"></tickist-tag>
    <tickist-tag fxFlex *ngFor="let tag of filteredTagsList$ | async; trackBy: trackByFn" [label]="tag.name" [id]="tag.id"
             [tasksCounter]="tag.tasksCounter" [tag]="tag"></tickist-tag>
    <tickist-tag fxFlex label="Without tags" id="withoutTags"></tickist-tag>
</div>

<form [formGroup]="createTagForm" (ngSubmit)="createTag(createTagForm.value)" #form="ngForm">
    <mat-form-field [ngStyle]="{'margin-left':'20px'}">
        <input matInput name="tag-name" formControlName="name" placeholder="Tag name">
        <mat-error *ngIf="createTagForm.controls['name'].hasError('required')">Name is required</mat-error>
    </mat-form-field>

    <button mat-button data-cy="createTag">Create tag</button>
</form>

