<div id="all-projects" fxLayout="row" fxLayoutAlign="center center">
    <span [dataCy]="projectType" fxFlex (click)="navigateToAllProjects(tasksProjectsViewRoutingName)">All projects</span>
    <span fxFlex (click)="navigateToAllProjects(tasksProjectsViewRoutingName)"></span>
    <tickist-menu-button [icon]="'filter'" (click)="openFilterDialog()">
    </tickist-menu-button>
    <mat-menu #projectsMenu="matMenu">
        <button mat-menu-item [routerLink]="['/home/project']">Create new project</button>
        <button mat-menu-item (click)="toggleProjectView()" *ngIf="!showOnlyProjectsWithTasks">Only projects
            with tasks
        </button>
        <button mat-menu-item (click)="toggleProjectView()" *ngIf="showOnlyProjectsWithTasks">All projects
        </button>
    </mat-menu>
</div>

<tickist-single-project [project]="project" [isSmallScreen]="isSmallScreen"
                *ngFor="let project of (projectsList$ | async);trackBy: trackByFn">
</tickist-single-project>
<div id="add-project" data-cy="create-new-project" fxLayout="row" fxLayoutAlign="start center" (click)="navigateToCreateProjectView()">
    <tickist-menu-button [icon]="'plus'" [color]="'#fcb150'">
    </tickist-menu-button>
    <span>Create new project</span>
</div>

